<template>
  <el-dialog
    :title="!dataForm.id ? '新增部门' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible" @closed="closeDialog()">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    
    <el-form-item label="所属组织" prop="orgId">
      <el-input :disabled="true" v-model="orgName" placeholder=""></el-input>
    </el-form-item>

<!--    <el-form-item label="上级部门" prop="parentDeptId">-->
<!--      <el-input :disabled="true" v-model="parentDeptName" placeholder=""></el-input>-->
<!--    </el-form-item>-->

    <el-form-item label="部门类型" prop="deptType">
      <sys-dict-select v-model="dataForm.deptType"  dictType="dept_type" />
    </el-form-item>
    
    <el-form-item label="业务网格" prop="regionId" v-if="dataForm.deptType == 'B'"  >
      <!-- <sysOrg :form="dataForm" field="regionId" :multiple="false" :lazy="true"></sysOrg> --> 
      <region-tree type="4" :inType="true" :rootId="rootRegionId" placeholder="请点击以选择网格" v-model="dataForm.regionId" > </region-tree>
    </el-form-item>

    <el-form-item label="部门名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="" maxlength="30"></el-input>
    </el-form-item>

    <el-form-item label="部门编码" prop="deptCode">
      <el-input v-model="dataForm.deptCode" placeholder="" maxlength="30"></el-input>
    </el-form-item>
    
<!--    <el-form-item label="部门别名" prop="deptAlias">-->
<!--      <el-input v-model="dataForm.deptAlias" placeholder=""></el-input>-->
<!--    </el-form-item>-->
    <!-- <el-form-item label="邮政" prop="post">
      <el-input v-model="dataForm.post" placeholder=""></el-input>
    </el-form-item>
    <el-form-item label="电话" prop="tel">
      <el-input v-model="dataForm.tel" placeholder=""></el-input>
    </el-form-item>
    <el-form-item label="传真" prop="fax">
      <el-input v-model="dataForm.fax" placeholder=""></el-input>
    </el-form-item>
    <el-form-item label="邮箱" prop="email">
      <el-input v-model="dataForm.email" placeholder=""></el-input>
    </el-form-item> -->
    <el-form-item label="排序" prop="orderBy">
      <el-input-number style="width: 100%;" v-model="dataForm.orderBy" :min="0" placeholder="请输入排序号"></el-input-number>
    </el-form-item> 
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/sys/sysdept.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        orgName: '',
        parentDeptName: '',
        rootRegionId: '',
        dataForm: {
          id: null,
          name: '',
          regionId:'',
          type:'',
          deptCode: '',
          parentDeptId: '',
          orgId: '',
          orderBy: '',
          post: '',
          tel: '',
          fax: '',
          email: '',
          deptAlias: '',
          deptType: '',
          deptIndexTreeNo: '',
          workCalendarId: '',
          validFlag: '',
          lastUpdateDate: '',
          lastUpdatedBy: '',
          creationDate: '',
          createdBy: '',
          lastUpdateIp: '',
          version: '',
          deptLevel: '',
          orgIdentity: '',
          deptPinyin: ''
        },
        dataRule: {
          name: [
            { required: true, message: '部门名称不能为空', trigger: 'blur' }
          ],
          deptCode: [
            { required: true, message: '部门编码不能为空', trigger: 'blur' }
          ],
          deptType: [
            { required: true, message: '部门类型不能为空', trigger: 'change' }
          ], 
          regionId: [
            { required: true, message: '业务网格不能为空', trigger: 'change' }
          ],
          orderBy: [
            { required: true, message: '排序不能为空', trigger: 'blur' }
          ],  
        }
      }
    },
    methods: {
      init (id, treeNode) {
        this.dataForm.id = id
        this.treeNode = treeNode;
        this.dataForm.orgId = this.treeNode.id;
        if(this.treeNode.type == "org") {
          this.dataForm.parentDeptId = 0
          this.orgName = treeNode.name;
          this.parentDeptName = '';
          this.rootRegionId = treeNode.orgRegionId;
        } else {
          this.orgName = treeNode.orgName;
          this.parentDeptName = treeNode.name;
          this.rootRegionId = treeNode.orgRegionId;
          this.dataForm.parentDeptId = this.treeNode.id
          this.dataForm.orgId = this.treeNode.orgId
        }

        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.data.name
                this.dataForm.deptCode = data.data.deptCode
                this.dataForm.parentDeptId = data.data.parentDeptId
                this.dataForm.orgId = data.data.orgId
                this.dataForm.orderBy = data.data.orderBy
                this.dataForm.post = data.data.post
                this.dataForm.tel = data.data.tel
                this.dataForm.regionId = data.data.regionId
                this.dataForm.fax = data.data.fax
                this.dataForm.email = data.data.email
                this.dataForm.deptAlias = data.data.deptAlias
                this.dataForm.deptType = data.data.deptType
                this.dataForm.deptIndexTreeNo = data.data.deptIndexTreeNo
                this.dataForm.workCalendarId = data.data.workCalendarId
                this.dataForm.validFlag = data.data.validFlag
                this.dataForm.lastUpdateDate = data.data.lastUpdateDate
                this.dataForm.lastUpdatedBy = data.data.lastUpdatedBy
                this.dataForm.creationDate = data.data.creationDate
                this.dataForm.createdBy = data.data.createdBy
                this.dataForm.lastUpdateIp = data.data.lastUpdateIp
                this.dataForm.version = data.data.version
                this.dataForm.deptLevel = data.data.deptLevel
                this.dataForm.orgIdentity = data.data.orgIdentity
                this.dataForm.deptPinyin = data.data.deptPinyin
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          } else {
            this.dataForm.name = ''
            this.dataForm.deptCode = ''
            this.dataForm.deptType = ''
            this.dataForm.regionId = ''
          }
        })
      },
      closeDialog(){
        // this.dataForm.regionId =''
        this.$emit("destoryOrg")
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
              'name': this.dataForm.name,
              'deptCode': this.dataForm.deptCode,
              'parentDeptId': this.dataForm.parentDeptId,
              'orgId': this.dataForm.orgId,
              'orderBy': this.dataForm.orderBy,
              'post': this.dataForm.post,
              'tel': this.dataForm.tel,
              'fax': this.dataForm.fax,
              'regionId': this.dataForm.deptType === 'B'? this.dataForm.regionId : '',  // 仅有业务部门才能设置业务网格
              'email': this.dataForm.email,
              'deptAlias': this.dataForm.deptAlias,
              'deptType': this.dataForm.deptType,
              'deptIndexTreeNo': this.dataForm.deptIndexTreeNo,
              'workCalendarId': this.dataForm.workCalendarId,
              'validFlag': this.dataForm.validFlag,
              'lastUpdateDate': this.dataForm.lastUpdateDate,
              'lastUpdatedBy': this.dataForm.lastUpdatedBy,
              'creationDate': this.dataForm.creationDate,
              'createdBy': this.dataForm.createdBy,
              'lastUpdateIp': this.dataForm.lastUpdateIp,
              'version': this.dataForm.version,
              'deptLevel': this.dataForm.deptLevel,
              'orgIdentity': this.dataForm.orgIdentity,
              'deptPinyin': this.dataForm.deptPinyin
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
                this.$emit('treeget');
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
